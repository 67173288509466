import { MeContext, StripeProductEnum, UserActivationStateEnum } from '@curvo/apollo'
import * as H from 'history'
import React, { useContext, useEffect } from 'react'
import Fullstory, { identify } from 'react-fullstory'
import { withRouter } from 'react-router-dom'
import env from '../configs/env'

function usePageViews(location: H.Location) {
  const { data } = useContext(MeContext)
  useEffect(() => {
    const rawWindow: any = window
    const _hsq = (rawWindow._hsq = rawWindow._hsq || [])
    if (env.env !== 'PROD') {
      _hsq.push(['doNotTrack'])
      return
    }
    if (data?.me) {
      const findPartSubscription = data.me.subscriptions.find(s => s.plan?.productType === StripeProductEnum.FindAPart)

      const newsletterSubscription = data.me.subscriptions.find(
        s => s.plan?.productType === StripeProductEnum.Newsletter,
      )

      const identificationData = {
        email: data.me.email,
        created_at: data.me.createdAt,
        onn_find_a_part: !!findPartSubscription,
        onn_find_a_part_expiration: findPartSubscription?.currentPeriodEnd
          ? findPartSubscription?.currentPeriodEnd * 1000
          : null,
        onn_is_active: data.me.isActive === UserActivationStateEnum.Active,
        onn_last_login_date: data.me.lastLogin,
        onn_newsletter_expiration: newsletterSubscription?.currentPeriodEnd
          ? newsletterSubscription?.currentPeriodEnd * 1000
          : null,
        renewal_date: newsletterSubscription?.currentPeriodEnd ? newsletterSubscription?.currentPeriodEnd * 1000 : null,
        onn_status: data.me.isActive,
        onn_newsletter: !!newsletterSubscription,
        onn_subscription_status: !!newsletterSubscription,
      }

      _hsq.push(['identify', identificationData])
    }
    _hsq.push(['setPath', location.pathname])
    _hsq.push(['trackPageView'])
  }, [location.state, data, location.pathname])
}

const Tracking: React.FC<{ location: H.Location }> = ({ location }) => {
  usePageViews(location)

  const FS_ORG_ID = 'UKb'
  const FS_NAMESPACE = 'FS'
  const HEAP_APP_ID = '3672313903' // "ONN Web-App" environment ID

  const { data: meData } = useContext(MeContext)

  // connect Heap in prod, regardless if user is logged in or not
  // heap and heap.load are defined in ./web-app/public/index.html
  if (env.env === 'PROD' && heap && typeof heap.load === 'function') {
    heap.load(HEAP_APP_ID)
    if (meData?.me.id) {
      heap.identify(meData.me.id)
      heap.addUserProperties({
        Name: `${meData.me.firstName} ${meData.me.lastName}`,
        Email: meData.me.email,
      })
    }
  }

  if (meData?.me.id && env.env && env.env !== 'dev') {
    identify(meData.me.id, {
      displayName: `${meData.me.firstName} ${meData.me.lastName}`,
      email: meData.me.email,
    })
  }

  return <Fullstory org={FS_ORG_ID} namespace={FS_NAMESPACE} />
}

export const WithRouterTracking = withRouter(Tracking)
